<template>
    <div>
        <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
                <template v-slot:header>
                    <h6 class="mb-0">Tạo mới</h6>
                </template>
                <b-row>
                    <b-col sm="6">
                        <b-form-group label="Tiêu đề (*)">
                            <b-form-input
                                v-model="input.title"
                                placeholder="Nhập tiêu đề"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group label="Link Tracking (*)">
                            <b-form-input
                                v-model="input.link"
                                placeholder="Nhập link"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group label="Logo (*):">
                            <b-form-file
                                @change="onImageChange($event,'logo')"
                                placeholder="chọn ảnh"
                                accept="image/*"
                                type="file"
                            ></b-form-file>
                            <div v-if="input.logo">
                                <img
                                    style="width: 100px;"
                                    :src="input.logo"
                                    :required="input.logo ? false : true"
                                    alt=""
                                />
                            </div>
                        </b-form-group>

                        <b-form-group label="Banner (*):">
                            <b-form-file
                                @change="onImageChange($event,'banner')"
                                placeholder="chọn ảnh"
                                accept="image/*"
                                type="file"
                            ></b-form-file>
                            <div v-if="input.banner">
                                <img
                                    style="width: 100px;"
                                    :src="input.banner"
                                    :required="input.banner ? false : true"
                                    alt=""
                                />
                            </div>
                        </b-form-group>

                        <b-form-group label="Background (*):">
                            <b-form-file
                                @change="onImageChange($event)"
                                placeholder="chọn ảnh"
                                accept="image/*"
                                type="file"
                            ></b-form-file>
                            <div v-if="input.background">
                                <img
                                    style="width: 100px;"
                                    :src="input.background"
                                    :required="input.background ? false : true"
                                    alt=""
                                />
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group
                            id="input-group-1"
                            label="Nội dung(*):"
                            label-for="input-2"
                            required
                        >
                            <vue-editor  required v-model="input.content"></vue-editor>

                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col class="text-center">
                        <b-button class="mr-2" v-if="this.id == '' || this.id == null" variant="outline-primary"
                                  @click="store()"
                        >Thêm mới
                        </b-button>
                        <b-button class="mr-2" v-if="this.id != '' && this.id != null" variant="outline-primary"
                                  @click="update()"
                        >Lưu
                        </b-button>
                        <b-button class="mr-2" variant="outline-danger" @click="cancel()"
                        >Hủy
                        </b-button>
                    </b-col>
                </b-row>
            </b-card>
        </b-card-group>
    </div>
</template>

<script>
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
import CmsRepository from "@/core/repositories/cmsRepository";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import {VueEditor} from "vue2-editor";
const WalletRunRepository = RepositoryFactory.get("WalletRun");

Vue.use(Notifications);
export default {
    mixins: [Common],
    components: {
        VueEditor
    },
    data() {
        return {
            input: {
                title: null,
                link: null,
                logo: null,
                banner: null,
                background: null,
                content: null,
            },
            id: this.$route.query.id ?? null
        };
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Config deeplink", route: '/tools/config-deeplink'},
        ]);
        if (this.$route.query.id != '' && this.$route.query.id != null) {
            this.getDetail(this.$route.query.id)
        }
    },
    async created() {
    },
    methods: {
        cancel() {
            return this.$router.push({name: "configDeeplink"});
        },
        validateStoreForm() {
            if (this.input.title === "" || this.input.title === null) {
                this.notifyAlert("warn", "Tiêu đề không được để trống");
                return false;
            }
            if (this.input.link === "" || this.input.link === null) {
                this.notifyAlert("warn", "Link không được để trống");
                return false;
            }
            if (this.input.icon === "" || this.input.icon === null) {
                this.notifyAlert("warn", "icon không được để trống");
                return false;
            }
            if (this.input.banner === "" || this.input.banner === null) {
                this.notifyAlert("warn", "banner không được để trống");
                return false;
            }
            if (this.input.background === "" || this.input.background === null) {
                this.notifyAlert("warn", "background không được để trống");
                return false;
            }
            if (this.input.content === "" || this.input.content === null) {
                this.notifyAlert("warn", "Nội đung không được để trống");
                return false;
            }
            return true;
        },
        store() {
            if (!this.validateStoreForm()) {
                return;
            }
            let inputParam = this.input;
            this.$bus.$emit("show-loading", true);
            CmsRepository.storeDeeplink(inputParam)
                .then((response) => {
                    if (response) {
                        this.notifyAlert("success", response.data.message);
                        this.$bus.$emit("show-loading", false);
                        return this.$router.push({name: "configDeeplink"});
                    } else {
                        this.notifyAlert("warn", response.data.message);
                        this.$bus.$emit("show-loading", false);
                        return false;
                    }
                })
                .catch(() => {
                    alert("Có lỗi xảy ra");
                });
        },
        update() {
            if (!this.validateStoreForm()) {
                return;
            }
            let inputParam = this.input;
            this.$bus.$emit("show-loading", true);
            inputParam.id = this.id
            CmsRepository.updateDeeplink(this.id, inputParam)
                .then((response) => {
                    if (response.data.error_code == 1) {
                        // this.notifyAlert("success", response.data.message);
                        this.$bus.$emit("show-loading", false);
                        return this.$router.push({name: "configDeeplink"});
                    } else {
                        this.notifyAlert("success", response.data.message);
                        this.$bus.$emit("show-loading", false);
                        return this.$router.push({name: "configDeeplink"});
                    }
                })
                .catch(() => {
                    alert("Có lỗi xảy ra");
                });
        },
        async getDetail(id) {
            this.$bus.$emit("show-loading", true);
            await CmsRepository.detailDeeplink(id)
                .then((response) => {
                    if (response.data.error_code !== 0) {
                        this.notifyAlert("warn", response.data.message);
                        this.$bus.$emit("show-loading", false);
                        return false;
                    }

                    let result = response.data.data.data;

                    this.input.title = result.title;
                    this.input.link = result.link;
                    this.input.logo = result.logo;
                    this.input.banner = result.banner;
                    this.input.background = result.background;
                    this.input.content = result.content;

                    this.$bus.$emit("show-loading", false);
                })
                .catch((error) => {
                    alert(error);
                    this.$bus.$emit("show-loading", false);
                });
        },
        onImageChange(e, type, position) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                return;
            }
            let fileSize = files[0].size / 1024 / 1024; // in MiB
            if (fileSize > 2) {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Chọn ảnh không vượt quá 2MB",
                });
                return false;
            }
            this.images = files[0];
            this.uploadMedia(type, position);
            //this.createImage(files[0]);
        },
        uploadMedia: async function (type) {
            this.$bus.$emit("show-loading", true);
            const formData = new FormData();
            formData.append("file", this.images);

            WalletRunRepository.uploadMedia(formData)
                .then((response) => {
                    this.$bus.$emit("show-loading", false);
                    if (1 === response.data.error_code) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: response.data.message,
                        });
                    } else {
                        if(type==='logo'){
                            this.input.logo = response.data.data.link;
                        }else if(type==='banner'){
                            this.input.banner = response.data.data.link;
                        }else{
                            this.input.background = response.data.data.link;
                        }
                    }
                })
                .catch((response) => {
                    this.$bus.$emit("show-loading", false);
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: response.data.message,
                    });
                });
        },
    },
};
</script>

